$(document).ready(function(){
    if($(window).innerWidth()<768) { 

        $(".modele-images-left-seconde .image-block, .modele-images-right-seconde .image-block").css({
            width: "100%"
        })
        
        $(".modele-images-left-seconde .image").css({
            height: "auto"
        });
    
        $(".modele-images-right-seconde .image").css({
            height: "auto"
        });

    } else {
        $(".modele-images-left-seconde .image-block, .modele-images-right-seconde .image-block").css({
            width: $(window).innerWidth()/2+"px"
        })

        if($(".modele-images-left-seconde .image .image-block").innerHeight() > 0){
            $(".modele-images-left-seconde .image").css({
                height: $(".modele-images-left-seconde .image .image-block").innerHeight()+"px"
            });
        }

        if($(".modele-images-right-seconde .image .image-block").innerHeight() > 0){
            $(".modele-images-right-seconde .image").css({
                height: $(".modele-images-right-seconde .image .image-block").innerHeight()+"px"
            });
        }
    }

    $(window).on("resize", function(){

        if($(window).innerWidth()<768) { 

            $(".modele-images-left-seconde .image-block, .modele-images-right-seconde .image-block").css({
                width: "100%"
            })
            
            $(".modele-images-left-seconde .image").css({
                height: "auto"
            });
        
            $(".modele-images-right-seconde .image").css({
                height: "auto"
            });

        } else {
            $(".modele-images-left-seconde .image-block, .modele-images-right-seconde .image-block").css({
                width: $(window).innerWidth()/2+"px"
            })
            
           if($(".modele-images-left-seconde .image .image-block").innerHeight() > 0){
                $(".modele-images-left-seconde .image").css({
                    height: $(".modele-images-left-seconde .image .image-block").innerHeight()+"px"
                });
            }

            if($(".modele-images-right-seconde .image .image-block").innerHeight() > 0){
                $(".modele-images-right-seconde .image").css({
                    height: $(".modele-images-right-seconde .image .image-block").innerHeight()+"px"
                });
            }
        }
        btn_article_more();
    });

    
    function btn_article_more() {

        if($("#article")){
            if($(".modele-images-right-seconde p > a").length > 0) {
                var $a = $(".modele-images-right-seconde p > a").clone();
                $("<div class='btn-class'>").insertAfter($(".modele-images-right-seconde p > a"));
                $(".modele-images-right-seconde .btn-class").append($($a).clone());
                $(".modele-images-right-seconde p > a").remove();
                $(".modele-images-right-seconde").find(".block").addClass("has-padding-130");
                $a = "";
            }
        
            if($(".modele-images-left-seconde p > a").length > 0) {
                var $a = $(".modele-images-left-seconde p > a").clone();
                $("<div class='btn-class'>").insertAfter($(".modele-images-left-seconde p > a"));
                $(".modele-images-left-seconde .btn-class").append($($a).clone());
                $(".modele-images-left-seconde p > a").remove();
                $(".modele-images-left-seconde").find(".block").addClass("has-padding-130");
                $a = "";
            }    
        }

        if($("#about")){
            $(".modele-about").each(function (index, element) {
                if($(element).find("p > a").length > 0) {
                    var $a = $(element).find("p > a").clone();
                    $("<div class='btn-class'>").insertAfter($(element).find("p > a"));
                    $(element).find("p .btn-class").append($($a).clone());
                    $(".btn-class").parent().addClass("pos-static");
                    $(element).find("p > a").remove();
                    $(element).addClass("has-padding-130");
                    $a = "";
                }
            });
        }
    }

    btn_article_more();
});