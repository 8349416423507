$(document).ready(function(){

    if($("#banniere").attr("data-image-src")) {
        $('#banniere').parallax({imageSrc: $("#banniere").attr("data-image-src")});
    }

   function fn_title(){
        $(".section .inner-title").each(function(index,elemnt){
            var height = $(elemnt).find("h3").innerHeight();
            $(elemnt).removeClass("line-1");
            $(elemnt).removeClass("line-2");
            $(elemnt).removeClass("line-3");
            

            if($(window).innerWidth()<767) {
                if(height<100) {
                    $(elemnt).addClass("line-1");
                } else if(height < 130 ) {
                    $(elemnt).addClass("line-2");
                } else {
                    $(elemnt).addClass("line-3");
                }
            } else {
                if(height<100) {
                    $(elemnt).addClass("line-1");
                } else if(height < 200 ) {
                    $(elemnt).addClass("line-2");
                } else {
                    $(elemnt).addClass("line-3");
                }
            }
        });

        $("#banniere .inner-title").each(function(index,elemnt){
            var height = $(elemnt).find("h3").innerHeight();
            $(elemnt).removeClass("line-1");
            $(elemnt).removeClass("line-2");
            $(elemnt).removeClass("line-3");

            

            if($(window).innerWidth()<767) {
                if(height <= 60) {
                    $(elemnt).addClass("line-1");
                } else if(height <=  120 ) {
                    $(elemnt).addClass("line-2");
                } else {
                    $(elemnt).addClass("line-3");
                }
            } else {
                if(height <= 100) {
                    $(elemnt).addClass("line-1");
                } else if(height <= 200 ) {
                    $(elemnt).addClass("line-2");
                } else {
                    $(elemnt).addClass("line-3");
                }
            }
        });
   }

   fn_title();

   if($(document).find(".esg-blurlistenerexists").length > 0) {
        $("#banniere").css("background-image","url('"+$("#banniere").attr("data-image-src")+"')");
        $(".parallax-mirror").remove();
   }

    $(document).on("click", ".btn-scroll",function(){

        $("html, body").animate({
            scrollTop: $(this).parents("#animation_container").innerHeight() +10
        },800)
    });

    $("#slider .items ").owlCarousel ({
        loop:true,
        margin:50,
        nav:true,
        autoWidth:true,
        responsive:{
            0:{
                items:1
            },
            500:{
                items:2
            },
            700:{
                items:3
            },
            1000:{
                items:4
            },
            1400:{
                items:5
            },
            1800:{
                items:6
            }
        }
    });

    $("#services .block-images .block").first().css({
        width: $(window).innerWidth()/2+"px"
    })

    if($(window).innerWidth()>768) {
        $(".modele-slider-right .image-block,.modele-slider-left .image-block").css({
            width: $(window).innerWidth()/2+"px"
        });
    } else {

        $("#services .block-images .block").first().css({
            width: "100%"
        })

        $(".modele-slider-right .image-block,.modele-slider-left .image-block").css({
            width: "100%"
        });
    }

    $(window).on("resize", function(){

        fn_title();
        
        if($(window).innerWidth()>768) {
            $(".modele-slider-right .image-block,.modele-slider-left .image-block").css({
                width: $(window).innerWidth()/2+"px"
            });
        } else {

            $("#services .block-images .block").first().css({
                width: "100%"
            })
    
            $(".modele-slider-right .image-block,.modele-slider-left .image-block").css({
                width: "100%"
            });
        }
    })
    
    $(".modele-slider-right .image-block").owlCarousel ({
        items:1,
        margin:0,
        nav: true,
        loop:true
    })

    $(".modele-slider-left .image-block").owlCarousel ({
        items:1,
        margin:0,
        nav: true,
        loop:true
    })
    
    $(document).on("scroll", function(){
        if($(document).scrollTop()>= $("#banniere, #animation_container").innerHeight()+20){
            $("#nav").addClass("fixed");
        } else {
            $("#nav").removeClass("fixed");
        }
    });

    $(document).on("click", "#btn-close", function(){
        if($(this).hasClass("open")){
            $(this).removeClass("open");
            $("#nav .menu").removeClass("open");
            $("body").removeClass("hidden");
        } else {
            $("body").addClass("hidden");
            $(this).addClass("open");
            $("#nav .menu").addClass("open");
        }
        return false;
    });

    $(document).on("click", ".filter-news", function(){
        var $href = $(this).find("a.eg-item-skin-news-element-2").attr("href");
        console.log($href);
        //window.location = $href;
        window.open($href, '_blank');
    });

    $(document).on("hover", function () { 
        $("").css("height", $(".sous-menu").height());
    });

    $(document).on("hover", function () { 
        $("").css({
            padding: "20px 0",
            height: $(".sous-menu").height(),
            overflow: "visible"
        })
    });

    $(document).on("hover", function () { 
        $("").css({
            padding: "20px 0",
            height: $(".sous-menu").height(),
            overflow: "visible"
        })
    });

/*
    $('#navbar .menu-item-has-children').mouseover(function() {
        var height = 0;
        $(".sous-menu li").each(function(index,element){
            height += $(element).height();
        });

        $("#nav .sous-menu").css({
            padding: "10px 0",
            height: (height+20)+"px",
            overflow: "visible"
        })
    });

    $('#nav .menu-item-has-children').mouseout(function() {
        $("#nav .sous-menu").css({
            padding: 0,
            height: 0,
            overflow: "hidden"
        })
    });
*/

    $(document).on("mouseenter", "#nav .menu-item-has-children", function(){
        var height = 0;
        $(".sous-menu li").each(function(index,element){
            height += $(element).height();
        });
        $("#nav .sous-menu").addClass("has-hover");
        $("#nav .sous-menu").css({
            height: (height+20)+"px"
        });
    });

    $(document).on("mouseleave", "#nav .menu-item-has-children", function () {
        $("#nav .sous-menu").removeClass("has-hover");
        $("#nav .sous-menu").css({
            height: 0
        });
    });

});
