$(document).ready(function(){
    var owl_full_img = $("#deail-slider .full-images .items").owlCarousel({
        items:1,
        nav:true,
        dots:false
    })

    var owl_block_img = $("#deail-slider .block-images .items").owlCarousel({
        nav:true,
        dots:false,
        margin:4,
        responsive:{
            0:{
                items:4
            },
            600:{
                items:5
            },            
            960:{
                items:6
            },
            1200:{
                items:8
            }
        }
    });

    $(document).on("click", ".block-images .item", function(){
        
        var index_item = $(this).parent().index();
        var index_img = $(".full-images .items .owl-item.active").index();

        if(index_img > index_item) {
            var index = index_img - index_item;
            for(var i =0; i<index; i++){
                owl_full_img.trigger('prev.owl.carousel');
            }
        } else {
            var index = index_item - index_img;
            for(var i =0; i<index; i++){
                owl_full_img.trigger('next.owl.carousel');
            }
        }
    });

    owl_full_img.on("dragged.owl.carousel",function(){
        var index_img = $(this).find(".owl-item.active").index();
        if($(".block-images .items .owl-item").eq(index_img-1).hasClass("active")){
            owl_block_img.trigger('next.owl.carousel');
        } else {
            owl_block_img.trigger('prev.owl.carousel');
        }
    });

    $(document).on("click", ".full-images .owl-prev", function(){
        var index_img =  $(this).parents(".items").find(".owl-item.active").index();
        console.log(index_img)
        if(!$(".block-images .items .owl-item").eq(index_img).hasClass("active")){
            owl_block_img.trigger('prev.owl.carousel');
        }
    });

    $(document).on("click", ".full-images .owl-next", function(){
        var index_img = $(this).parents(".items").find(".owl-item.active").index();
        if(!$(".block-images .items .owl-item").eq(index_img).hasClass("active")){
            owl_block_img.trigger('next.owl.carousel');
        }
    });

    $(document).on("click", ".full_screen", function(){
        openFullscreen()
    });
});